import * as React from "react";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../../common/NotificationMessage";
import { useCreateAxios } from "../../../../hooks/useCreateAxios";
import { Button, Box, Typography, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import themePrimary from "../../../../styles/themePrimary";
import DateSearchField, { getDefaultEndDate } from "../../../common/search/DateSearchField"
import SearchIcon from '@mui/icons-material/Search';
import { ItvSessionsResponse } from "../../../../models/reporting/itv/ItvSessions";
import { useUser } from "../../../../providers/UserProvider";
import axios from "axios";
import { getItvSessions } from "../../../../api/reporting/itv/reportingApi";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme: Theme) => ({
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    },
    summaryCard: {
        height: '100%',
        display: 'flex',
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main + " !important",
    },
    summaryCardTextContainer: {
        margin: 'auto',
    },
    text: {
        color: themePrimary.palette.primary.contrastText,
        textAlign: 'center',
    }
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setMonth(0, 1);

    return date;
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function ItvSessions() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = React.useState<Date>(getDefaultEndDate);
    const [viewData, setViewData] = React.useState<ItvSessionsResponse | undefined>(undefined);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const theme = useTheme();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const classes = useStyles(); 

    React.useEffect(() => {
        async function apiGetItvSessions() {
            try {
                const payload = await getItvSessions(axiosInstance, user?.currentProperty?.code ?? "", startDate, endDate);
                setViewData(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };            
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingMenuItemViews.replace("{{error}}", error.message), 
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (isLoading) {
            apiGetItvSessions();
        }
        
    }, [user.currentProperty?.code, isLoading, startDate, endDate])

    function onSearchClick() {
        setIsLoading(true);
    }

    function onDateSearchFieldChangeHandler (startDate: Date, endDate: Date) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function formatDuration(duration: number): String {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
      
        var formattedHours = String(hours).padStart(2, '0');
        var formattedMinutes = String(minutes).padStart(2, '0');

        var stringDuration = ""

        if (formattedHours.substring(0, 1) === "0") {
            formattedHours = formattedHours.substring(1, formattedHours.length)
        }

        if (formattedMinutes.substring(0, 1) === "0") {
            formattedMinutes = formattedMinutes.substring(1, formattedMinutes.length)
        }

        if (parseInt(formattedHours) > 0) {
            if (formattedHours === "1") {
                stringDuration = formattedHours + " " + strings.hour + ", "
            }
            else {
                stringDuration = formattedHours + " " + strings.hours + ", "
            }
        }

        if (formattedMinutes === "1") {
            stringDuration += formattedMinutes + " " + strings.minute
        }
        else {
            stringDuration += formattedMinutes + " " + strings.minutes
        }

        return stringDuration
    }

    return (
        <Box sx={{ padding: theme.spacing(2) }}>
            <Box className={classes.searchBox}>
                <DateSearchField 
                    startDate={getDefaultSearchStartDate()}
                    endDate={null}
                    format={null}
                    onChange={onDateSearchFieldChangeHandler} 
                />
                
                <Button
                    sx={{ marginLeft: themePrimary.spacing(2) }}
                    variant="contained"
                    size="large"
                    disabled={false}
                    onClick={onSearchClick}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </Box>

            {viewData !== undefined && !isLoading && 
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card variant="outlined" className={classes.summaryCard}>
                            <div className={classes.summaryCardTextContainer}>
                                <Typography variant="h5" component="div" className={classes.text}>
                                    {strings.reportingItvSessionsCount}
                                </Typography>
                                <Typography variant="h4" component="div" className={classes.text}>
                                    {viewData?.count}
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card variant="outlined" className={classes.summaryCard}>
                            <div className={classes.summaryCardTextContainer}>
                                <Typography variant="h5" component="div" className={classes.text}>
                                    {strings.reportingItvSessionsAvgDuration}
                                </Typography>
                                <Typography variant="h4" component="div" className={classes.text}>
                                    {formatDuration(viewData?.averageDuration)}
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card variant="outlined" className={classes.summaryCard}>
                            <div className={classes.summaryCardTextContainer}>
                                <Typography variant="h5" component="div" className={classes.text}>
                                    {strings.reportingItvSessionsLongestDuration}
                                </Typography>
                                <Typography variant="h4" component="div" className={classes.text}>
                                    {formatDuration(viewData?.maximumDuration)}
                                </Typography>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            }
            

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default ItvSessions;  